<template>
  <div class="job-wrap wrapper">
    <a-spin :spinning="loading">
      <a-empty v-if="job.length == 0" description="尚未发布职位"></a-empty>
      <job-list :data="job" :user="user_id"></job-list>
      <a-pagination
        v-model:current="page"
        v-model:pageSize="limit"
        :defaultPageSize="15"
        :hideOnSinglePage="true"
        show-size-changer
        :total="total"
        :pageSizeOptions="['15', '30', '60', '120']"
        @showSizeChange="onShowSizeChange"
      />
      <a-button
        block
        size="large"
        type="primary"
        class="btn"
        @click="goJobForm"
      >
        发布新职位
      </a-button>
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import { message } from "ant-design-vue";
import { getUserJobList } from "@/api/main.js";
import { getUserId } from "@/utils/storeData.js";
import JobList from "./components/list.vue";
import url from "@/utils/url.js";
export default defineComponent({
  name: "Job",
  components: {
    JobList
  },
  setup() {
    const pageData = reactive({
      job: [],
      page: 1,
      limit: 15,
      total: 0,
      loading: false,
      user_id: computed(() => getUserId())
    });
    const getUserJobListFun = () => {
      pageData.loading = true;
      getUserJobList({
        page: pageData.page,
        limit: pageData.limit,
        user_id: pageData.user_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.job = res.data.job;
            pageData.total = res.count;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    getUserJobListFun();
    const onShowSizeChange = (_page, _limit) => {
      pageData.page = _page;
      pageData.limit = _limit;
    };
    const goJobForm = () => {
      url.navigateTo("/job/form");
    };
    watch(
      [() => pageData.page, () => pageData.limit],
      val => {
        getUserJobListFun();
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...toRefs(pageData),
      onShowSizeChange,
      goJobForm
    };
  }
});
</script>
<style lang="less" scoped>
.job-wrap {
  padding: 40px 0 100px 0;
}
</style>
