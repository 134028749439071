<template>
  <div class="job-list-wrap">
    <div
      v-for="(item, index) in data"
      :key="index"
      class="job-list"
      @click="goJobDetail(item)"
    >
      <div class="flex flex-between job-list-top">
        <div class="text-black">
          <div class="font-24">{{ item.job_name }}</div>
          <div class="font-18 mt-10 text-gray">{{ item.job_company_name }}</div>
        </div>
        <div class="font-28 font-bold text-blue">
          {{ item.job_salary_min }}-{{ item.job_salary_max }}
        </div>
      </div>
      <div class="job-status-wrap" v-if="user">
        <div
          :class="{
            job_status: true,
            'label-gray': item.job_audit_status == -1,
            'label-red': item.job_audit_status == 0
          }"
        >
          {{ transferStatusName(item.job_audit_status) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch,
  ref
} from "@vue/runtime-core";
import { encode } from "js-base64";
import url from "@/utils/url.js";

export default defineComponent({
  name: "JobList",
  props: {
    data: {
      type: Array,
      default: function () {
        return [];
      }
    },
    user: {
      type: String,
      default: null
    }
  },
  setup(props) {
    const transferStatusName = _status => {
      let statusName = ["审核驳回", "等待审核", "审核通过"];
      return statusName[_status + 1];
    };
    const goJobDetail = _item => {
      url.navigateTo("/job/detail", {
        t: encode(`${_item.job_id}`)
      });
    };
    return {
      transferStatusName,
      goJobDetail
    };
  }
});
</script>

<style lang="less">
.job-list-wrap {
  .job-list {
    box-shadow: 0px 0px 27px 0px #eeeeee;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 10px;
    padding: 0 20px;
    .job-list-top {
      padding: 20px 0;
      align-items: baseline;
    }
  }
  .job-status-wrap {
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
    border-top: 1px solid #dfdfdf;
    .job_status {
      width: 81px;
      height: 23px;
      line-height: 23px;
      border-radius: 11px;
      border: solid 1px #1276cb;
      color: #1276cb;
      text-align: center;
    }
  }
}
</style>
